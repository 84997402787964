<template>
  <v-dialog
    width="100%"
    max-width="1600px"
    v-model="dialog"
    scrollable
    persistent
    @keydown.esc="dialog = false"
  >
    <v-card :loading="loading">
      <v-system-bar window dark
        ><span>Kursrechnung bearbeiten</span><v-divider />
        <v-btn icon @click="dialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-system-bar
      >
      <v-card-text class="pt-4">
        <v-form v-model="saveAllowed">
          <v-row v-if="item">
            <v-col cols="12" md="6" lg="5" xl="4" offset-xl="2">
              <v-card>
                <v-card-title
                  ><span v-if="isNew"> neue Rechnung</span>
                  <span v-else>Rechnung {{ item.number }}</span></v-card-title
                >
                <v-list>
                  <v-list-item>
                    <PersonInput
                      v-model="item.employee"
                      group="employee"
                      label="Angestellte"
                    />
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      ref="event"
                      v-model="item.event"
                      label="Anlass"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      ref="description"
                      v-model="item.description"
                      label="Bezeichnung"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Erstellt am</v-list-item-subtitle>
                      <v-list-item-title
                        ><DateValue :value="item.date" long />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card class="mt-4">
                <v-list>
                  <v-list-item>
                    <LookupValueInput
                      :items="accounts"
                      v-model="item.account"
                      label="Konto"
                    />
                  </v-list-item>

                  <v-list-item
                    v-if="
                      item.account && item.account.code === 'teachingMaterial'
                    "
                  >
                    <GradesInput v-model="item.grades" />
                  </v-list-item>
                  <v-list-item
                    v-if="item.account && item.account.code === 'fieldTrip'"
                  >
                    <SchoolYearInput
                      v-model="item.schoolYear"
                      :range="[-1, 1]"
                      label="Schuljahr"
                    ></SchoolYearInput>
                  </v-list-item>

                  <v-list v-if="!gym1OK">
                    <v-list-item>
                      <v-alert type="error">
                        Schüler:innen GYM2-4 können keine Rechnungen auf Stufe
                        GYM1 haben!
                      </v-alert>
                    </v-list-item>
                  </v-list>
                </v-list>
              </v-card>
              <v-card class="mt-4">
                <v-list>
                  <v-list-item>
                    <LookupValueInput
                      :items="payees"
                      v-model="item.payee"
                      label="Zahlung"
                    />
                  </v-list-item>
                  <v-list-item v-if="copies">
                    <v-row no-gutters>
                      <v-col>
                        <v-list-item>
                          <v-text-field
                            label="Kopien s/w pro Schüler*in"
                            @focus="$event.target.select()"
                            type="number"
                            min="0"
                            v-model="copiesGrayscale"
                          ></v-text-field>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item>
                          <v-text-field
                            label="Kopien farbig pro Schüler*in"
                            @focus="$event.target.select()"
                            type="number"
                            min="0"
                            v-model="copiesColor"
                          ></v-text-field>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-list-item v-if="copies">
                    <v-list-item-content>
                      Kosten pro Person</v-list-item-content
                    >
                    <v-list-item-action
                      ><CurrencyValue v-model="copiesAmount" />
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item
                    v-if="item.payee && item.payee.code == 'employee'"
                  >
                    <v-text-field
                      ref="iban"
                      v-model="item.iban"
                      label="IBAN"
                      :rules="[rules.iban]"
                    ></v-text-field>
                  </v-list-item>
                </v-list>
                <v-divider v-if="copies" />
                <v-card-actions v-if="copies"
                  ><v-spacer /><v-btn text @click="assign">Zuweisen</v-btn>
                </v-card-actions>
              </v-card>
              <v-card class="mt-4">
                <InvoiceAmount
                  v-model="item"
                  @updateTolerance="updateTolerance"
                />
                <v-divider />
                <v-list v-if="!mixedGradesOK">
                  <v-list-item>
                    <v-alert type="error">
                      Schüler:innen GYM1 und GYM2-4 können nicht gemischt
                      werden!
                    </v-alert>
                  </v-list-item>
                </v-list>

                <v-card-actions>
                  <v-btn text @click="distribute">gleichmässig verteilen</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" lg="7" xl="4">
              <v-card v-if="item">
                <v-row no-gutters align="center" class="flex-nowrap">
                  <v-col cols="6">
                    <v-list-item class="font-weight-medium">
                      <v-list-item-icon>
                        <v-badge :content="item.details.length">
                          <v-icon>mdi-account-multiple</v-icon>
                        </v-badge>
                      </v-list-item-icon>
                      {{ item.details.length }} Personen
                    </v-list-item>
                  </v-col>
                  <v-col cols="2"> Spesen </v-col>
                  <v-col cols="3">
                    <CurrencyInput v-model="amountToSet" />
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      class="mx-2"
                      icon
                      color="success"
                      :small="$vuetify.breakpoint.xs"
                      :large="$vuetify.breakpoint.lgAndUp"
                      @click="setAmount"
                      ><v-icon>mdi-arrow-down-circle-outline</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider />
                <v-row
                  no-gutters
                  v-for="(detail, index) in item.details"
                  :key="index"
                  align="center"
                  class="flex-nowrap"
                >
                  <v-col cols="6" class="pl-2">
                    <PersonItem
                      small
                      :dense="$vuetify.breakpoint.xs"
                      :value="detail.person"
                    ></PersonItem>
                  </v-col>
                  <v-col cols="2">
                    <v-switch
                      v-if="detail.person.type.code === 'teacher'"
                      v-model="detail.expenses"
                      inset
                    ></v-switch>
                  </v-col>
                  <v-col cols="3">
                    <CurrencyInput v-model="detail.amount" />
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      class="mx-2"
                      icon
                      color="error"
                      :small="$vuetify.breakpoint.xs"
                      :large="$vuetify.breakpoint.lgAndUp"
                      @click="removeDetail(detail)"
                      ><v-icon>mdi-trash-can</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider />
                <v-card-actions>
                  <CoursePicker color="success" @input="addCourse" />
                  <v-spacer />
                  <PersonPicker color="success" @input="addPersons" />
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn text @click="dialog = false">abbrechen</v-btn>
        <v-spacer />
        <v-btn
          text
          color="info"
          @click="save"
          :loading="saving"
          :disabled="!savable"
          >Speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import CoursePicker from "./Components/CoursePicker.vue";
import CurrencyInput from "@/components/CurrencyInput.vue";
import CurrencyValue from "@/components/CurrencyValue.vue";
import DateValue from "common/components/DateValue.vue";
import GradesInput from "./Components/GradesInput.vue";
import InvoiceAmount from "./Components/InvoiceAmount.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonInput from "common/components/PersonInput.vue";
import PersonItem from "common/components/PersonItem.vue";
import PersonPicker from "./Components/PersonPicker.vue";
import SchoolYearInput from "common/components/SchoolYearInput.vue";
import { compareStudentsBySchoolClass } from "common/utils/people.js";
import { isValidIBANNumber } from "@/utils/helper.js";
export default defineComponent({
  components: {
    CoursePicker,
    CurrencyInput,
    CurrencyValue,
    DateValue,
    GradesInput,
    InvoiceAmount,
    LookupValueInput,
    PersonInput,
    PersonItem,
    PersonPicker,
    SchoolYearInput,
  },
  props: { id: {}, page: { type: String, default: "InvoicePending" } },
  data() {
    return {
      accounts: [],
      amountToSet: 0.0,
      copiesColor: 0,
      copiesGrayscale: 0,
      dialog: true,
      loading: false,
      item: {
        amount: 0,
        details: [],
        grades: { 1: false, 2: false, 3: false, 4: false },
        schoolYear: {},
      },
      priceGrayscale: 10,
      priceColor: 35,
      payees: [],
      rules: {
        required: (value) => !!value || "wird benötigt.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        iban: (value) => {
          return isValidIBANNumber(value) || "Ungültige IBAN.";
        },
        iban2: (value) => {
          const pattern =
            /^CH\d{2}[ ]\w{4}[ ]\w{4}[ ]\w{4}[ ]\w{4}[ ]\w{1}|CH\d{2}\w{17}$/;
          return pattern.test(value) || "Ungültige IBAN.";
        },
        amount: (value) => {
          const pattern = /^$|^.*\d+(\.\d{2})?$/;
          return pattern.test(value) || "Üngültiger Betrag.";
        },
      },
      saveAllowed: false,
      saving: false,
      tolerance: true,
    };
  },
  computed: {
    gym1OK() {
      if (this.item.details.length < 1) return true;
      if (!this.item.grades[4]) return true;
      const hasOther = this.item.details.some(
        (el) => el.person.grade && el.person.grade.code != "GYM1"
      );
      return !hasOther;
    },
    mixedGradesOK() {
      if (!this.item.account || this.item.account.code === "fieldTrip")
        return true;
      if (this.item.details.length < 1) return true;
      const hasGym1 = this.item.details.some(
        (el) => el.person.grade && el.person.grade.code == "GYM1"
      );
      const hasOther = this.item.details.some(
        (el) => el.person.grade && el.person.grade.code != "GYM1"
      );
      return hasGym1 != hasOther;
    },
    copies() {
      return this.item.payee && this.item.payee.code == "schoolFund";
    },
    copiesAmount() {
      return (
        (this.priceGrayscale * this.copiesGrayscale +
          this.priceColor * this.copiesColor) /
        100
      );
    },
    isNew() {
      return !this.id;
    },
    savable() {
      return (
        this.item.amount > 0 &&
        this.item.description &&
        this.item.details.length > 0 &&
        this.item.account &&
        this.item.payee &&
        this.tolerance &&
        this.mixedGradesOK &&
        this.gym1OK
      );
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        if (this.isNew) {
          this.$router.push({
            name: "FinanceInvoicesSubmitted",
          });
        } else {
          this.$router.push({
            name: "FinanceInvoicesSubmittedDetail",
            params: { id: this.item.id },
          });
        }
      }
    },
  },
  methods: {
    async addCourse(course) {
      const students = await this.apiList({
        resource: "person/person",
        query: `course=${course.id}`,
      });
      if (!this.item.event) this.item.event = course.title;
      students.forEach((person) => {
        if (!this.hasDetail(person))
          this.item.details.push({ person: person, amount: 0 });
      });
      this.sortDetails();
    },
    async addPersons(persons) {
      for (const person of persons) {
        if (!this.hasDetail(person)) {
          // Bei Mitarbeitern und Konto Exkursionen werden per Default Spesen erfasst
          const expenses =
            person.type.code === "teacher" &&
            this.item.account.code === "fieldTrip";
          this.item.details.push({
            person: person,
            amount: 0,
            expenses: expenses,
          });
        }
      }
      this.sortDetails();
    },
    assign() {
      const details = this.item.details;
      details.forEach((detail) => (detail.amount = this.copiesAmount));
      this.item.amount = this.copiesAmount * details.length;
    },
    distribute() {
      const details = this.item.details;
      const amount =
        Math.floor((100 * this.item.amount) / details.length) / 100;
      details.forEach((detail) => (detail.amount = amount));
    },
    hasDetail(person) {
      const details = this.item.details;
      for (let i = 0; i < details.length; ++i) {
        if (details[i].person.id === person.id) return true;
      }
      return false;
    },
    updateTolerance(tolerance) {
      this.tolerance = tolerance;
    },
    removeDetail(detail) {
      const index = this.item.details.indexOf(detail);
      this.item.details.splice(index, 1);
    },
    async save() {
      this.saving = true;
      let newId = this.id;
      if (this.item.payee.code == "employee") {
        localStorage.setItem("iban", this.item.iban);
      }
      // deep copy
      const data = JSON.parse(JSON.stringify(this.item));
      // replace person-json with person-id
      data.details.forEach((detail) => (detail.person = detail.person.id));
      if (this.isNew) {
        const result = await this.apiPost({
          resource: "finance/invoice",
          data: data,
        });
        newId = result.id;
      } else {
        await this.apiPut({
          resource: "finance/invoice",
          id: this.id,
          data: data,
        });
      }
      this.saving = false;
      this.$emit("update");
      this.$router.push({
        name: "FinanceInvoicesSubmittedDetail",
        params: { id: newId },
      });
    },
    setAmount() {
      this.item.details.forEach((detail) => (detail.amount = this.amountToSet));
    },
    sortDetails() {
      this.item.details.sort((a, b) =>
        compareStudentsBySchoolClass(a.person, b.person)
      );
    },
  },
  async created() {
    this.loading = true;
    this.accounts = await this.apiList({
      resource: "finance/invoiceaccount?course",
    });
    this.payees = await this.apiList({ resource: "finance/payee" });
    if (this.isNew) {
      this.item.iban =
        this.$_profilePerson.iban || localStorage.getItem("iban");
    } else {
      this.item = await this.apiGet({
        resource: "finance/invoice",
        id: this.id,
      });
      this.sortDetails();
    }
    this.loading = false;
  },
});
</script>
